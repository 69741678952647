.system {
  min-width: 1250px;
  overflow-x: scroll;
  height: 100vh;
  background: #F7F8FA;
}

.el-footer ::v-deep {
  color: #999999;
  font-size: 14px;
  text-align: center;
  line-height: 40px;

  a {
    color: #999999;
  }
}

.leftNav {
  position: absolute;
  left: 0;

  .logo {
    height: 60px;
    background: #C1DBFD;
    justify-content: center;
    width: 180px;

    img {
      height: 80%;
    }
  }

  .btnCollapse {
    font-size: 30px;
    margin-left: 24px;
    cursor: pointer;
  }
}

.el-header {
  background: linear-gradient(to left, #83B7FD, #81B6FD);
  text-align: center;
  line-height: 60px;
  position: relative;
  color: #FFFFFF;

  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    justify-content: center;
    color: #FFFFFF;
    letter-spacing: 2px;

    img {
      margin-right: 10px;
    }
  }

  .right {
    position: absolute;
    right: 16px;
    top: 0;
  }

  .el-dropdown {
    color: #FFFFFF;
  }

  .time {
    margin-right: 20px;
  }

  .name {
    margin-left: 20px;
    cursor: pointer;
  }
}

.el-aside {
  background: #EAF3FF;
}

.navbar ::v-deep {
  .el-menu-item {
    min-width: 100%;
  }

  .el-submenu__title, .el-menu-item {
    height: 50px;
    line-height: 50px;
  }


  .name {
    margin-left: 5px;
  }


}

i.iconfont {
  font-size: 20px;
  margin-right: 5px;
  color: #bbbbbb;
}

::v-deep .el-submenu__title:hover, .el-menu-item:hover {
  background: transparent !important;
}

.navbar.el-submenu.is-opened.activeItem {
  .el-menu--inline .active {
    color: #4F9AFE !important;
    font-weight: bold;
  }
}


.el-main {
  padding: 0;
  overflow-y: scroll;
  height: 88vh;
}

.default {
  padding: 16px;
  background: url("~@/assets/img/default.png") no-repeat bottom #FFFFFF;
  background-size: contain;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);

  h2 {
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 28px;
    margin-top: 20px;
  }
}

.navbarList {
  width: 70%;
  margin: 58px auto;
  justify-content: center;
  flex-wrap: wrap;

  .commList {
    width: 110px;
    height: 110px;
    background: #FFFFFF;
    border-radius: 13px;
    box-shadow: 0 2px 8px 0 rgba(44, 77, 105, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-right: 20px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 2;
    color: #333333;
  }

  .iconClass {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    line-height: 56px;
    text-align: center;

    i.iconfont {
      font-size: 32px;
      color: #FFFFFF;
      margin-right: 0;
    }
  }

  p {
    margin: 5px 0;
  }

  .line {
    width: 15px;
    height: 3px;
    background: #61D57B;
    border-radius: 2px;
  }

  .iconA {
    background: linear-gradient(0deg, #60D57B 0%, #8EE786 100%);
  }

  .iconB {
    background: linear-gradient(0deg, #A66DFA 0%, #C497FE 100%);
  }

  .iconC {
    background: linear-gradient(0deg, #6E6EFA 0%, #9797FE 100%);
  }

  .iconD {
    background: linear-gradient(0deg, #FE855A 0%, #FEA27D 100%);
  }

  .iconE {
    background: linear-gradient(0deg, #FAB163 0%, #F7C187 100%);
  }

  .iconF {
    background: linear-gradient(0deg, #58B2F1 0%, #68CDF7 100%);
  }

  .iconG {
    background: linear-gradient(0deg, #FF8C8C 0%, #FEAAAA 100%);
  }

  .iconH {
    background: linear-gradient(0deg, #FF8DB2 0%, #FEABC6 100%);
  }

  .lineB {
    background: #A76DFA;
  }
  .lineC {
    background: #6E6EFA;
  }
  .lineD {
    background: #FE865B;
  }
  .lineE {
    background: #FAB163;
  }
  .lineF {
    background: #10AFF1;
  }
  .lineG {
    background: #FF8C8C;
  }
  .lineH {
     background: #FF8DB2;
   }
}
