@font-face {
  font-family: "iconfont"; /* Project id 1365013 */
  src: url('iconfont.woff2?t=1629854867023') format('woff2'),
       url('iconfont.woff?t=1629854867023') format('woff'),
       url('iconfont.ttf?t=1629854867023') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icona-05longsheguanli:before {
  content: "\e634";
}

.icona-02shengchanketi:before {
  content: "\e636";
}

.icona-04jianyiqueren:before {
  content: "\e637";
}

.icona-01shiyanketi:before {
  content: "\e638";
}

.icona-06wodeshenpi:before {
  content: "\e639";
}

.icona-03caigouqueren:before {
  content: "\e63a";
}

.icona-07xitongguanli:before {
  content: "\e63b";
}

.iconbushushezhi:before {
  content: "\e622";
}

.iconjiaoseshezhi:before {
  content: "\e623";
}

.iconcloud-user:before {
  content: "\e631";
}

.iconjiaoseguanli:before {
  content: "\e60b";
}

.iconbumen:before {
  content: "\e703";
}

.iconcaidan:before {
  content: "\e62e";
}

.icondayin:before {
  content: "\e61e";
}

.iconduihao1:before {
  content: "\e635";
}

.iconguanbi:before {
  content: "\e61d";
}

.iconshenqing1:before {
  content: "\e61a";
}

.iconjiaojie1:before {
  content: "\e61b";
}

.iconshenhe:before {
  content: "\e61c";
}

.iconxitongzijian:before {
  content: "\ea3a";
}

.icondongwusiyang:before {
  content: "\e60f";
}

.iconhuanjingqingjie:before {
  content: "\e610";
}

.iconhuanjingjiance1:before {
  content: "\e614";
}

.iconcaigouguanli:before {
  content: "\e611";
}

.icondongwushiyan:before {
  content: "\e612";
}

.iconlunlishenpi:before {
  content: "\e613";
}

.iconziyuanguanli:before {
  content: "\e615";
}

.iconxitongguanli:before {
  content: "\e616";
}

.iconrichangguanli:before {
  content: "\e617";
}

.iconxiangmuguanli:before {
  content: "\e618";
}

.iconshiyanfangan:before {
  content: "\e619";
}

